<template>
 <div class="loss-page"> 
    <img class="lossContent" src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/web/dist/img/lossHeader.png">
     <el-scrollbar ref="scrollbar" style="height: 10.6rem;background: #EDEDED;paddingBottom:10px"  :options="{damping: 1}" id="my-scrollbar" class="my-scrollbar">
      <!-- <div class="dialogue" ref="scrollbar"> -->
        <template v-for="(item,index) in Answered" >
          <div :class="item.css=='down' ? 'down':'lossLeft'" :id="'loss'+item.id" v-if="item.key=='Answered'" :key="index">
            <img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/web/dist/img/kefuHeader.png" class="header">      
            <div class="answer" :style="item.show ? '' :'height:39px;paddingTop:0'">
              <div class="message" :style="item.show ? '' :'lineHeight:39px'">
                {{item.message}}
              </div>
              <div v-if="item.data.length > 0 && item.show" class="answerList">
                <div v-for="(answerItem,answerIndex) in item.data" :key="answerIndex" class="answerItem" @click="setAnswer(item.id,answerItem)">{{answerItem}}</div>
              </div>
            </div>
          </div>
          <div class="lossRight" v-if="item.key=='answer'" :key="index">        
            <div class="answerli">
              {{item.answer}}
            </div>
            <img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/web/dist/img/userHeader.png" class="header">
          </div>
        </template>
        <div class="last" v-if="showlast==5">
          <img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/web/dist/img/kefuHeader.png" class="header">  
          <div class="getPlan">
            <div style="lineHeight: 21px;">
              您已完成所有测试！
            </div>
            <div style="lineHeight: 21px;">1. 点击下方【获取蜕变方案】按钮</div>
            <div style="lineHeight: 21px;">2. 添加二维码即可获得方案及福利</div>
            <div style="font-family: HarmonyOS_Sans_SC;font-size: 14px;color: #F2554B;margin-top:17px;margin-left:4px;lineHeight: 21px;">↓↓立即获取↓↓</div>
            <div style="font-family: HarmonyOS_Sans_SC;font-size: 14px;color: #F2554B;margin-left:4px;lineHeight: 21px;">↓↓立即获取↓↓</div>
            <div style="font-family: HarmonyOS_Sans_SC;font-size: 14px;color: #F2554B;margin-left:4px;lineHeight: 21px;">↓↓立即获取↓↓</div>
            <div class="getButton" @click="openPop">获取蜕变方案</div>
          </div>        
        </div>
      <!-- </div> -->
    </el-scrollbar>
    <img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/web/dist/img/lossFooter2.png">
    <div class="mask" v-if="showPop" @click="closePop"></div>
    <div class="pop" v-if="showPop">
      <img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/web/dist/img/lossPop.png" class="qr">
      <div class="close" @click="closePop">
        <img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/web/dist/img/lossClose.png">
      </div>
    </div>
 </div>
</template>

<script>
let wx = window.wx
import axios from '../../util/axios'
import api from '../../util/api'
const permission = [  'onMenuShareTimeline','onMenuShareAppMessage','onMenuShareQQ','onMenuShareWeibo','updateAppMessageShareData','updateTimelineShareData']
axios.post(api.api.apiurl.getConfig,{path:window.location.href,permission,app:2}).then(res=>{  
  const config = JSON.parse(res.data.data.config)
  const obj ={
    ...config
  } 
  
  wx.config({...obj})	
  setTimeout(() => {     
  //配置自定义分享内容
  window.share_config = {
    'share': {
        'imgUrl': 'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/web/dist/img/linkImg.jpg', // 这里是需要展示的图标
        'desc': '不节食，不运动，瘦得快，好坚持！点击咨询', // 这是分享展示的摘要
        'title': '专业减肥瘦身', // 这是分享展示卡片的标题
        'link': window.location.href, // 这里是分享的网址
        'success': function(rr) {
            console.log('成功' + JSON.stringify(rr))
        },
        'cancel': function(tt) {
            console.log('失败' + JSON.stringify(tt));
        }
    }
  };
  wx.ready(function(res) {
    console.log(res,'----成功')
      wx.onMenuShareAppMessage(window.share_config.share); // 微信好友
      wx.onMenuShareTimeline(window.share_config.share); // 微信朋友圈
      wx.onMenuShareQQ(window.share_config.share); // QQ
      wx.updateAppMessageShareData(window.share_config.share);
      wx.updateTimelineShareData(window.share_config.share);
  });	
  }, 1000);	 
  wx.error(function(res){
    console.log(res,'----失败')
  // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
  })			
})
import { Scrollbar } from 'element-ui';
 
export default {
 data(){
 return {
  [Scrollbar.name]: Scrollbar,
  questionnaire:[
    {
      key:'Answered',
      message:'您好，我是您的健康顾问。请根据自身情况，点击下方选项，获取专业方案。',    
      data:[],
      show:true,
      id:1  
    },
    {
      key:'Answered',
      message:'您的年龄？',
      data:['18~30岁','30~40岁','40~50岁','50岁以上'],
      show:true,
      id:2
    },
    {
      key:'Answered',
      message:'您的身高？',
      data:['160cm以下','160-170cm','170-180cm','180cm以上'],
      show:true,
      css:'down',
      id:3
    },
    {
      key:'Answered',
      message:'最想哪里变美？',
      data:['全身','手臂肉','大肚腩','大象腿'],
      show:true,
      css:'down',
      id:4
    },
    {
      key:'Answered',
      message:'您想蜕变多少斤？',
      data:['10斤以下','10-30斤','30-50斤','50斤以上'],
      show:true,
      css:'down',
      id:5
    },
  ],
  Answered:[
     {
      key:'Answered',
      message:'您好，我是您的健康顾问。请根据自身情况，点击下方选项，获取专业方案。',
      show:true,    
      data:[],
      id:1    
    },
    {
      key:'Answered',
      message:'您的年龄？',
      id:2,
      show:true,
      data:['18~30岁','30~40岁','40~50岁','50岁以上']
    },
  ],
  showlast:1,
  showPop:false,
  targetScrollTop: 0, // 目标滚动位置
  currentScrollTop: 0, // 当前滚动位置
  scrollDuration: 500, // 滚动动画持续时间（毫秒）
  scrollStep: null, // 每一帧滚动的距离
  animationFrame: null,
  time:true
 }

 },
 created(){
  // this.getConfig()
 },
 methods:{ 
  setAnswer(id,item){
    const Answered = this.Answered    
    const answer = {
      key:'answer',
      id,
      answer:item,
      show:true,
      data:[]
    }  
    Answered.map(item=>{
      if(item.id==id){
        item.show=false
      }
    })
    Answered.push(answer)
    if(id<5){    
      Answered.push(this.questionnaire[id])      
    }     
    this.Answered = Answered
    this.showlast = id
    const loss = `loss${id+1}`
    this.scrollToBottom(loss)    
  },
  scrollToBottom() {
    this.$nextTick(() => {
      const scrollbar = this.$refs.scrollbar.$refs.wrap; // 获取滚动区域的DOM元素
      scrollbar.scrollTo({ top:scrollbar.scrollHeight, behavior: 'smooth'})      
    });
  },

  openPop(){
    this.showPop=true
  },
  closePop(){
    this.showPop=false
  },
 }

}

</script>

<style scoped>
.loss-page{
  display: flex;
  flex-direction: column;
}
.lossContent{
  
}
.loss-page>img{
  width: 100vw;
  height: auto;
}
.dialogue{
  position: relative;
  width: 100vh;
  height: 9rem;
  background: #EDEDED;
  box-sizing: border-box;
  padding: 16px 0;
  overflow: scroll;
  transition:transform 0.3s;  
}
.header{
  width: 54px;
  height: 54px;
}
.lossLeft{
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-left: 22px;
  margin-top: 24px;
  animation: lossLeft .9s ;
  -webkit-animation: lossLeft .9s ;
}
.down{
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-left: 22px;
  margin-top: 24px;
  animation: lossDown .3s ;
  -webkit-animation: lossDown .3s ;
}
@keyframes lossDown {
  from {
      transform: translateX(-50%);
      /* transform: translateY(50%); */
      opacity: 0;
  }
  to {
      transform: translateX(0);
      opacity: 1;
  }
}
@-webkit-keyframes  lossDown {
  from {
      transform: translateX(-50%);
      /* transform: translateY(50%); */
      opacity: 0;
  }
  to {
      transform: translateX(0);
      opacity: 1;
  }
}
@-webkit-keyframes  lossLeft {
  from {
      transform: translateX(-80%);
      transform: translatey(-50%);
      opacity: 0;
  }
  to {
      transform: translateX(0);
      transform: translatey(0);
      opacity: 1;
  }
}
@keyframes lossLeft {
  from {
      transform: translateX(-80%);
      transform: translatey(-50%);
      opacity: 0;
  }
  to {
      transform: translateX(0);
      transform: translatey(0);
      opacity: 1;
  }
}
.lossRight{
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  padding-right: 22px;
  margin-top: 24px;
}
.last{
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-left: 22px;
  margin-top: 24px;
  animation: lossDown .3s ;
  -webkit-animation: lossDown .3s ;

}
.getPlan{
  width: 240px;
  height: 223px;
  border-radius: 4px;
  background: #ffffff;
  font-family: HarmonyOS_Sans_SC;
  font-size: 14px;
  color: #141414;
  text-align: left;
  padding: 10px 8px;
  margin-left: 9px;
}
.getButton{
  border-radius: 2px;
  width: 214px;
  height: 36px;
  background: #F2554B;
  border-radius: 2px;
  text-align: center;
  line-height: 36px;
  margin-top: 18px;
  margin:0 auto;
  font-family: HarmonyOS_Sans_SC;
  font-size: 13px;
  color: #FFFFFF;
  margin-top: 18px;
}
.answer{
  max-width: 240px;
  min-height: 39px;
  background: #ffffff;
  box-sizing: border-box;
  padding: 0 10px;  
  padding-top: 14px;  
  padding-bottom: 19px;  
  margin-left: 9px;  
  flex-shrink: 0;
  border-radius: 4px;
}
.message{
  font-family: HarmonyOS_Sans_SC;
  font-size: 14px;
  color: #141414;
  line-height: 21px;
  text-align: left;
}
.answerList{
  display: flex;
  flex-wrap: wrap;
  margin-top: 18px;
  gap: 6px;
}
.answerItem{
  width: 107px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid #D8E2F5;
  text-align: center;
  line-height: 30px;
  font-family: HarmonyOS_Sans_SC;
  font-size: 13px;
  color: #508EFF;
}
.answerli{
  width: auto;
  padding:0 10px;
  height: 39px;
  line-height: 39px;
  background: #8CBF5A;
  border-radius: 4px;
  margin-right: 8px;
}
.my-scrollbar{
  scroll-behavior: smooth;
}
.my-scrollbar .el-scrollbar__bar {
  /* 设置滚动条的基本样式 */
  scroll-behavior: smooth;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
}
 
.my-scrollbar .el-scrollbar__thumb {
  scroll-behavior: smooth;
  transition: transform 0.3s ease; /* 平滑滚动动画 */
}

.mask{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.9);
  z-index: 1;
}
.pop{
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.close{
  width: 0.75rem;
  height: 0.75rem;
  margin-top: 32px;
}
.close img{
  width: 0.75rem;
  height: 0.75rem;  
}
.qr{
  width: 7.6rem;
  height: 10.7rem;
}
</style>
